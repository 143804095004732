<script setup>
import {Head, usePage, } from "@inertiajs/vue3"
import Feedback from '@/Components/Feedback.vue'
import {computed, reactive, ref, watch} from "vue"
import BasketIcon from "@/Pages/Shop/Checkout/Basket/Partials/BasketIcon.vue"
import {isActiveMenuItem} from './Helper/navmenuHelper.js'
import ProductSearch from "@/Pages/Shop/ProductSearch/ProductSearch.vue";

const props = defineProps({
    title: {
        type: String,
        default: null
    },
    isPost: {
        type: Boolean,
        default: false
    },
    isBasket: {
        type: Boolean,
        default: false
    },
    isGalleries: {
        type: Boolean,
        default: false
    }
})

const pageProps = usePage().props
const { categories, adminMenuNavLinks } = pageProps
const { user } = pageProps.auth ?? {}

const order = ref(0)
const drawer = ref(false)
const rail = ref(false)

let open = ref([])
let openAdmin = ref([])

const isSearchPoppedOut = ref(false)
const updatePopoutStatus = (update) => {
    isSearchPoppedOut.value = update
}
</script>

<template>
    <Head :title="title"/>
    <v-app>
        <v-layout class="d-flex flex-column">
            <v-navigation-drawer
                :rail="rail"
                v-model="drawer"
                :rail-width="60"
                width="200"
                color="primary"
            >
                <v-list-item nav height="77px" class="nav-header rounded-0">
                    <v-row>
                        <v-col cols="auto" class="d-flex justify-end">
                            <v-app-bar-nav-icon>
                                <a :href="route('shop.index')">
                                    <img src="/static/images/ibwa_round.png"
                                         :height="35"
                                    />
                                </a>
                            </v-app-bar-nav-icon>
                            <template v-if="rail === false">
                                <div class="w-100 ml-2">
                                    <p class="text-white">I.B.W.A</p>
                                    <p class="font-size-12 text-grey">Artist & Illustrator</p>
                                </div>
                            </template>
                        </v-col>
                    </v-row>
                </v-list-item>
                <v-list v-model:opened="openAdmin" v-if="user?.is_admin">
                    <v-list-group value="Management" class="management-chevron">
                        <template v-slot:activator="{ props }">
                            <v-list-item
                                v-bind="props"
                            >
                                <template #title>
                                    <p class="text-accent menu-title-position">Management</p>
                                </template>
                                <template #prepend>
                                    <v-icon color="accent">fa-regular fa-list-check</v-icon>
                                </template>
                            </v-list-item>
                        </template>
                        <v-list-item
                            v-for="(adminMenuNavLink, i) in adminMenuNavLinks"
                            :key="i"
                            :prepend-icon="adminMenuNavLink.icon"
                            :title="adminMenuNavLink.title"
                            :value="adminMenuNavLink.title"
                            :href="adminMenuNavLink.href"
                            :class="{ 'v-list-item--active': isActiveMenuItem(adminMenuNavLink.slug, usePage().props.currentURL) }"
                            v-if="user?.is_admin"
                        >
                            <template #title>
                                <p class="text-accent menu-title-position">{{ adminMenuNavLink.title }}</p>
                            </template>
                            <template #prepend>
                                <v-icon color="accent" class="font-size-20">{{adminMenuNavLink.icon}}</v-icon>
                            </template>
                        </v-list-item>
                    </v-list-group>
                </v-list>
                <v-list v-model:opened="open">
                    <v-list-group value="Shop" class="shop-chevron">
                        <template v-slot:activator="{ props }">
                            <v-list-item
                                v-bind="props"
                            >
                                <template #title>
                                    <p class="text-text menu-title-position">Shop</p>
                                </template>
                                <template #prepend>
                                    <v-icon color="text">fas fa-shop</v-icon>
                                </template>
                            </v-list-item>
                        </template>
                        <v-list-group value="Shop">
                            <v-list-item
                                v-for="(category, i) in categories"
                                :key="i"
                                :prepend-icon="category.icon"
                                :title="category.title"
                                :value="category.title"
                                :href="category.href"
                                :class="{ 'v-list-item--active': isActiveMenuItem(category.slug, usePage().props.currentURL)}"
                            >
                                <template #title>
                                    <p class="text-text menu-title-position">{{ category.title }}</p>
                                </template>
                                <template #prepend>
                                    <v-icon color="text" class="font-size-20">{{category.icon}}</v-icon>
                                </template>
                            </v-list-item>
                        </v-list-group>
                    </v-list-group>
                    <v-list-item link :href="route('posts.index')" :class="{ 'v-list-item--active': isPost }">
                        <template #title>
                            <p class="text-text menu-title-position">Posts</p>
                        </template>
                        <template #prepend>
                            <v-icon color="text">fa-regular fa-pen-to-square</v-icon>
                        </template>
                    </v-list-item>
                    <v-list-item link :href="route('galleries.index')" :class="{ 'v-list-item--active': isGalleries }">
                        <template #title>
                            <p class="text-text menu-title-position">Galleries</p>
                        </template>
                        <template #prepend>
                            <v-icon color="text">fa-solid fa-gallery-thumbnails</v-icon>
                        </template>
                    </v-list-item>
                    <v-list-item link :href="route('logout')" v-if="user">
                        <template #title>
                            <p class="text-text menu-title-position">Log Out</p>
                        </template>
                        <template #prepend>
                            <v-icon color="text">fas fa-sign-out-alt</v-icon>
                        </template>
                    </v-list-item>
                    <v-list-item link :href="route('login')" v-else>
                        <template #title>
                            <p class="text-text menu-title-position">Log In</p>
                        </template>
                        <template #prepend>
                            <v-icon color="text">fas fa-sign-in-alt</v-icon>
                        </template>
                    </v-list-item>
                </v-list>
            </v-navigation-drawer>
            <v-app-bar height="85" flat color="primary" class="border-b-xl main-header w-100" prominent>
                <v-row class="d-flex align-center ml-0">
                    <v-col cols="12" class="d-flex align-center">
                        <v-app-bar-nav-icon
                            @click.stop="drawer = !drawer"
                            variant="text"
                            color="text"
                            size="36"
                            class="mr-3"
                        />
                        <v-app-bar-title :class="{'text-text':true, 'd-none d-sm-block':isSearchPoppedOut}" :text="title"/>
                        <ProductSearch v-model:popout="isSearchPoppedOut"/>
                        <BasketIcon class="mx-1"/>
                    </v-col>
                </v-row>
            </v-app-bar>
            <v-main class="align-center justify-center">
                <slot name="noGutter"/>
                <v-container>
                    <Feedback v-if="usePage().props.flash"/>
                    <v-row>
                        <v-col cols="12">
                            <slot/>
                        </v-col>
                    </v-row>
                </v-container>
            </v-main>
            <v-footer color="footer" style="max-height: 75px; z-index: 907;">
                <v-row class="d-flex justify-center text-center">
                    <v-col cols="12">
                        <v-icon color="text" class="mr-2 font-size-16 pb-5">far fa-copyright</v-icon>
                        <p class="d-inline-block text-text">2012 - {{ new Date().getFullYear() }} I.B.W.A</p>
                    </v-col>
                </v-row>
            </v-footer>
        </v-layout>
    </v-app>
</template>

<style scoped>
.v-list-item.v-list-item--link {
    padding: 0 12px !important;
}

:deep(.management-chevron .v-list-item__append .fas.fa-chevron-up::before),
:deep(.management-chevron .v-list-item__append .fas.fa-chevron-down::before) {
    color: rgb(var(--v-theme-accent));
}

:deep(.shop-chevron .v-list-item__append .fas.fa-chevron-up::before),
:deep(.shop-chevron .v-list-item__append .fas.fa-chevron-down::before) {
    color: rgb(var(--v-theme-text));
}

.main-header {
    border-bottom-color: rgb(var(--v-theme-text)) !important;
}
</style>

